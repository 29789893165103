<template>
  <div class="section-slider w-100 mb-5 pb-3 pt-2 pb-xxl-5 pt-xxl-5">
    <b-container class="pl-xxl-0 pr-xxl-0">
      <h2 class="title-section text-center pt-4 pt-xxl-5 pb-3 mb-2 mb-xxl-5">
        <esports-icon-game /> <span>人気ゲーム</span>
      </h2>
      <template v-if="sliderEvents.length">
        <VueSlickCarousel v-bind="settings" dots>
          <div
            class="card-thumb"
            v-for="(game, index) in sliderEvents"
            :key="index"
          >
            <div class="thumb-article">
              <b-img-lazy v-bind="mainProps()" :src="game.picture" :alt="game.title" show></b-img-lazy>
              <h4 class="mt-4 font-weight-bold">
                {{ game.title }}
              </h4>
            </div>
          </div>
        </VueSlickCarousel>
      </template>
      <p class="text-center py-2 py-xxl-5">
        <b-button :title="$t('general_inputs.btn_view_all')"  href="/games" class="btn-slash mt-xxl-3"
          @mouseover="handleOverBtn"
          @mouseleave="handleLeaveBtn"
          :class="{'animate__animated animate__pulse': animated}"
          ><span class="position-relative">{{ $t('general_inputs.btn_view_all') }}</span></b-button
        >
      </p>
    </b-container>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
// import "vue-slick-carousel/dist/vue-slick-carousel.css";

export default {
  name: "SectionSlider",
  components: { VueSlickCarousel },
  data() {
    return {
      settings: {
        centerMode: false,
        centerPadding: "87px",
        focusOnSelect: true,
        infinite: true,
        slidesToShow: 3,
        speed: 500,
        arrows: false,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              centerPadding: "32px",
            },
          },
          {
            breakpoint: 375,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              centerPadding: "42px",
            },
          },
        ],
      },
      isLoading: false,
      sliderEvents: [],
      limit: false
    };
  },
  async mounted() {
    await this.getGameTop();
  },
  methods: {
    async getGameTop() {
      this.isLoading = true;
      const result = await this.$store
        .dispatch("home/getGameTop", { limit: this.limit })
        .catch((err) => {
          console.log(err);
        });

      if (result) {
        this.sliderEvents = result.map((event, index) => {
          event.picture = `${process.env.VUE_APP_BACKEND + event.picture}`;
          return event;
        });

         this.sliderEvents =  this.sliderEvents.slice(0, 6);

        if(this.sliderEvents.length > 3) {
          this.settings.centerMode = true;
        }

        if (window.innerWidth <= 767) {
          this.settings.centerMode = true;
        }

        this.isLoading = false;
      }
    },
  },
};
</script>